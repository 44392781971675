import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerHero from "../components/InnerHero"

const Success = ({ page }) => {
  return (
    <Layout>
      <Seo
        title="Success Web design & web development near me - Vermont Web Development"
        description="A top web designer and web developer from Vermont. I share a vision with our clients, and when they succeed, they are successful too.."
      />
      <main>
        <section className="success-page">
          <div className="section u-padding-bottom">
            {!page && (
              <>
                <InnerHero>
                  <h1 className="heading__main-title heading__main-title--black">
                    Subscription
                  </h1>
                </InnerHero>
              </>
            )}
          </div>
          <section className="section u-padding">
            <div className="u-center">
              <div className="row">
                <div className="col-1-of-1">
                  <article>
                    <h2 className="heading-secondary heading-secondary--title">
                      Your submission was recieved!
                    </h2>
                  </article>
                </div>
              </div>
            </div>
          </section>

          <div className="row u-padding-bottom">
            <div className="col-1-of-1">
              <button aria-label="button">
                <Link to="/" className="btn btn--primary btn--animated">
                  back home
                </Link>
              </button>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default Success
